@import url('https://fonts.googleapis.com/css2?family=Mali&family=Mountains+of+Christmas&display=swap');

body {
  margin: 0;

  font-family: 'Mali', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  text-shadow: 0 4px 3px rgba(0, 0, 0, 0.63);


  background: linear-gradient(90deg, #25640c, #8d840a, #630c0c, #12489e);
  background-size: 800% 800%;

  -webkit-animation: gradient 60s ease infinite;
  -moz-animation: gradient 60s ease infinite;
  animation: gradient 60s ease infinite;

}

@-webkit-keyframes gradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes gradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes gradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 768px) {
  .form-container {
      flex-direction: column;
  }
}

.form-container {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.input-style {
  flex: 1;
}

.group {
  margin-top: 45px;
}

.dates-group {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}